import { createSlice, nanoid } from "@reduxjs/toolkit";
//@ts-ignore
import { FlyToInterpolator } from "@deck.gl/core";

const initialState = {
  configlayers: {},
  objectInfo: {},
  viewState: {
    longitude: -1.008479591430076,
    latitude: 51.81723594512922,
    zoom: 7,
    minZoom: 2,
    maxZoom: 24,
    bearing: 0,
    pitch: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  },
  dataTableVisible: false,
  dataTableActiveKey: "",
  dataInfo: {},
  filters: {},
  metaData: {},
  categories: [],
  selectedColumn: "Select Column",
};

const DeckModel = createSlice({
  name: "deckgl",
  initialState,
  reducers: {
    clearDeckState: () => initialState,
    saveConfigLayers(state, { payload }) {
      state.configlayers = {
        ...state.configlayers,
        ...payload,
      };
    },
    addObjectInfo(state, { payload }) {
      state.objectInfo = payload;
    },
    editConfigLayers(state, { payload: { layers, id } }) {
      //@ts-ignore
      state.configlayers[id] = { ...layers };
    },
    changeViewState(state, { payload }) {
      state.viewState = { ...state.viewState, ...payload };
    },
    setDataTableActiveKey(state, { payload }) {
      state.dataTableActiveKey = payload;
    },
    setDataTableVisible(state, { payload }) {
      state.dataTableVisible = payload;
    },
    editDataInfo(state, { payload }) {
      state.dataInfo = {
        ...state.dataInfo,
        [payload.id]: {
          ...payload.content,
        },
      };
    },

    addFilter(state, { payload }) {
      //@ts-ignore
      state.filters[payload] = [
        {
          fieldId: nanoid(4),
          data: {
            fieldBasedOn: "",
            values: [],
            range: [],
            minmax: [],
          },
        },
      ];
    },
    setFilter(state, { payload }) {
      state.filters = { ...state.filters, ...payload };
    },
    updateFilters(state, { payload }) {
      state.filters = payload;
    },
    deleteFilter(state, { payload }) {
      //@ts-ignore
      delete state.filters[payload];
    },
    addFilterField(state, { payload: { datasetId } }) {
      //@ts-ignore
      state.filters[datasetId] = [
        //@ts-ignore
        ...state.filters[datasetId],
        {
          fieldId: nanoid(4),
          data: {
            fieldBasedOn: "",
            values: [],
            range: [],
            minmax: [],
          },
        },
      ];
    },
    deleteFilterField(state, { payload: { datasetId, fieldId } }) {
      //@ts-ignore
      state.filters[datasetId] = state.filters[datasetId].filter(
        (item: any) => item.fieldId !== fieldId
      );
    },
    updateFilterFieldData(
      state,
      { payload: { datasetId, fieldId, value, range, minmax, values } }
    ) {
      //@ts-ignore
      state.filters[datasetId] = state.filters[datasetId].map((field: any) =>
        field.fieldId === fieldId
          ? {
              fieldId: fieldId,
              data: {
                fieldBasedOn: value,
                values: values,
                range: range,
                minmax: minmax,
              },
            }
          : field
      );
    },
    updatemetadata(state, { payload }) {
      state.metaData = {
        ...state.metaData,
        ...payload,
      }
    },
    updateCategoriesState(state, { payload }) {
      state.categories = payload;
    },
    setSelectedColumn(state, {payload}) {
      state.selectedColumn = payload;
    }
  },
});

export default DeckModel.reducer;
export const {
  clearDeckState,
  saveConfigLayers,
  editConfigLayers,
  changeViewState,
  addObjectInfo,
  setDataTableActiveKey,
  setDataTableVisible,
  editDataInfo,
  addFilter,
  addFilterField,
  deleteFilter,
  deleteFilterField,
  updateFilterFieldData,
  updateFilters,
  updatemetadata,
  updateCategoriesState,
  setSelectedColumn,
} = DeckModel.actions;
