import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectData: [],
  selectedProjectName: "",
  tablesList: [],
  selectedTableName: "",
  recordCount: 0,
  columnList: [],
  selectedDataColumn: "",
  selectedDatasetKey: "",
  datasetFormKey: "",
  columnData: [],
  // loadDataModel: false,
  // selectedProject: "",

  // datasetKey: "",
  // metaInformationModal: false,
  // sliderValue: 0,
  // columnDescriptionModal: false,
  // columnData: [],
  // metaInformation: {},
};

const DashboardModel = createSlice({
  name: "dashboardmodel",
  initialState,
  reducers: {
    saveProjectData(state, { payload }) {
      state.projectData = payload;
    },
    saveTablesList(state, { payload }) {
      state.tablesList = payload;
    },
    saveProjectName(state, { payload }) {
      state.selectedProjectName = payload;
    },
    saveTableName(state, { payload }) {
      state.selectedTableName = payload;
    },
    saveRecordCount(state, { payload }) {
      state.recordCount = payload;
    },
    saveColumnList(state, { payload }) {
      state.columnList = payload;
    },
    setSeletedDataColumn(state, { payload }) {
      state.selectedDataColumn = payload;
    },
    resetProjectData(state) {
      state.tablesList = [];
      state.selectedTableName = "";
      state.recordCount = 0;
      state.datasetFormKey = "";
    },
    saveDatasetKey(state, { payload }) {
      state.selectedDatasetKey = payload;
    },
    saveDatasetFormKey(state, { payload }) {
      state.datasetFormKey = payload;
    },
    saveColumnData(state, { payload }) {
      state.columnData = payload;
    },
  },
});

export default DashboardModel.reducer;

export const {
  saveProjectData,
  saveTablesList,
  saveProjectName,
  saveTableName,
  saveRecordCount,
  resetProjectData,
  saveDatasetKey,
  saveDatasetFormKey,
  saveColumnData,
  saveColumnList,
  setSeletedDataColumn,
} = DashboardModel.actions;
