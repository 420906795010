import { createSlice } from "@reduxjs/toolkit";
import { UserModelTypes } from "./data";

const initialState: UserModelTypes = {
  activePage: "Map",
  pageIndex: 1,
  currentUser: {},
  loggedIn: false,
  data: {},
  uploadData: {},
  newUser: false,
  isAdmin: false,
  permissions: {
    meta: {},
    read: [],
    write: [],
  },
  allUsers: [],
  dno: "ukpn"
};

const userModel = createSlice({
  name: "user",
  initialState,
  reducers: {
    queryActivePage(state, { payload: { pageName, index } }) {
      state.activePage = pageName;
      state.pageIndex = index;
    },
    saveUser(state, { payload }) {
      state.currentUser = payload;
      state.loggedIn = payload ? true : false;
    },

    saveData(state, { payload }) {
      state.data = payload;
    },

    updateData(state, { payload: { datasetId, data } }) {
      state.data[datasetId] = data;
    },

    clearUser: () => {
      window.localStorage.clear();
      return initialState;
    },

    setIsAdmin(state, { payload }) {
      state.isAdmin = payload;
    },
    setIsNewUser(state, { payload }) {
      state.newUser = payload;
    },
    updatePermissions(state, { payload }) {
      state.permissions = payload;
    },
    updateUploadData(state, { payload: { datasetId, data } }) {
      state.uploadData[datasetId] = data;
    },
    updateUploadDataProgress(state, { payload: { datasetId, progress } }) {
      state.uploadData[datasetId].loaded = progress;
    },
    updateToken(state, { payload }) {
      state.currentUser.token = payload;
    },
    updateAllUsers(state, { payload }) {
      state.allUsers = payload;
    },
    setDno(state, {payload}) {
      state.dno = payload;
    }
  },
});

export const {
  queryActivePage,
  saveUser,
  clearUser,
  saveData,
  updateData,
  setIsAdmin,
  updatePermissions,
  updateUploadData,
  updateUploadDataProgress,
  updateToken,
  updateAllUsers,
  setIsNewUser,
  setDno,
} = userModel.actions;
export default userModel.reducer;
